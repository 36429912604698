const SUPPORTED_LOCALES = {
    en: 'English',
    nl: 'Nederlands',
    fr: 'French'
};

/**
 * Function to get the app's supported locales
 * @returns {Array} annotatedLocales
 */
export function getSupportedLocales() {
    const annotatedLocales = [];

    Object.keys(SUPPORTED_LOCALES).forEach((code) => {
        annotatedLocales.push({
            code,
            name: SUPPORTED_LOCALES[code],
        });
    });

    return annotatedLocales;
}

/**
 * Function to check if locale is supported
 * @param {String} locale Country code
 */
export function supportedLocalesInclude(locale) {
    return Object.keys(SUPPORTED_LOCALES).includes(locale);
}
